<template>
  <div>
    <nav
      class="
        navbar navbar-expand-md navbar-dark
        bg-primary
        py-0
        flex-row-reverse
      "
    >
      <!-- ********************************************************************* -->
      <!-- * Since we aren't using branding, this is a hack to keep all of     * -->
      <!-- * the social media icons visible in the navbar even when things     * -->
      <!-- * are collapsed.  That is why we are using 'flex-row-reverse' above * -->
      <!-- * it keeps the branding ordered to the right side                   * -->
      <!-- ********************************************************************* -->
      <div class="navbar-brand">
        <a
          class="fa-nav"
          href="mailto:haverhillhammersyouthwrestling@yahoo.com"
          target="_blank"
        >
          <font-awesome-icon
            :icon="['fas', 'envelope-square']"
            size="1x"
            class="mr-1"
          ></font-awesome-icon>
        </a>
        <a
          class="fa-nav"
          href="https://www.facebook.com/HaverhillHammersYouthWrestling"
          target="_blank"
        >
          <font-awesome-icon
            :icon="['fab', 'facebook-square']"
            size="1x"
            class=""
          ></font-awesome-icon>
        </a>
      </div>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navBarContent"
        aria-controls="navBarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <font-awesome-icon class="fa-nav" :icon="['fas', 'bars']" size="1x">
        </font-awesome-icon>
      </button>

      <div id="navBarContent" class="collapse navbar-collapse">
        <ul class="navbar-nav mx-auto">
          <li
            v-for="link in links"
            :key="link.route + '-nav-link'"
            class="nav-item"
            :class="{ dropdown: isDropdown(link) }"
          >
            <!-- ********************************************************************* -->
            <!-- If our link has sub-category links, display things as a dropdown menu -->
            <!-- ********************************************************************* -->
            <div v-if="isDropdown(link)">
              <a
                :id="link.route + '-nav-link'"
                class="nav-link dropdown-toggle mx-md-1 mx-lg-3 py-0"
                data-toggle="dropdown"
              >
                {{ link.label }}
              </a>
              <div class="dropdown-menu">
                <router-link
                  v-for="childLink in link.children"
                  :key="childLink.route + '-nav-link'"
                  :to="{ name: childLink.route }"
                  class="dropdown-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  {{ childLink.label }}
                </router-link>
              </div>
            </div>

            <!-- ********************************************************************* -->
            <!--        Otherwise, display things as our normal, everyday links        -->
            <!-- ********************************************************************* -->
            <div v-else>
              <router-link
                :to="{ name: link.route }"
                class="nav-link mx-md-1 mx-lg-3 py-0"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {{ link.label }}
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'navBar',
  data() {
    return {
      links: [
        {
          label: 'Home',
          route: 'home',
        },
        {
          label: 'Registration',
          route: 'register',
        },
        {
          label: 'Programs',
          route: 'programs',
        },
        {
          label: 'Schedule',
          route: 'schedules',
        },
        // {
        //   label: 'Team Store',
        //   route: 'teamstore',
        // },
        {
          label: 'About Us',
          route: 'about',
        },
        // {
        //   label: 'Our Sponsors',
        //   route: 'sponsors',
        // },
        {
          label: 'Contact Us',
          route: 'contact',
          children: [
            {
              label: 'Coaches',
              route: 'coaches',
            },
            {
              label: 'Board of Directors',
              route: 'board',
            },
          ],
        },
      ],
    }
  },
  methods: {
    isDropdown(link) {
      return link.children && link.children.length > 0
    },
  },
}
</script>

<style scoped></style>
