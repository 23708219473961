import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import regFunctions from '@/commons/registrationFunctions'

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home.vue'),
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/about.vue'),
    },
    {
      path: '/sponsors',
      name: 'sponsors',
      component: () => import('@/views/sponsors.vue'),
    },
    {
      path: '/register',
      name: 'register',
      redirect: (to) => {
        return regFunctions.isRegistrationOpen()
          ? { name: 'registration' }
          : { name: 'registrationClosed' }
      },
    },
    {
      path: '/registration',
      name: 'registration',
      component: () => import('@/views/registration/registration.vue'),
    },
    {
      path: '/registration-closed',
      name: 'registrationClosed',
      component: () => import('@/views/registration/registrationClosed.vue'),
    },
    {
      path: '/registration/registered',
      name: 'registered',
      props: true,
      component: () => import('@/views/registration/registered.vue'),
    },
    {
      path: '/programs',
      name: 'programs',
      component: () => import('@/views/programs.vue'),
    },
    {
      path: '/schedules',
      name: 'schedules',
      component: () => import('@/views/schedules.vue'),
    },
    {
      path: '/teamstore',
      name: 'teamstore',
      component: () => import('@/views/teamStore.vue'),
    },
    {
      path: '/contact/coaches',
      name: 'coaches',
      component: () => import('@/views/coaches.vue'),
    },
    {
      path: '/contact/board',
      name: 'board',
      component: () => import('@/views/board.vue'),
    },
    // {
    //   path: "/events",
    //   name: "events",
    //   component: () =>
    //       import("@/views/events.vue")
    // },
    // {
    //   path: "/flyers",
    //   name: "flyers",
    //   component: () =>
    //       import("@/views/flyers.vue")
    // },
    // {
    //   path: "/sponsors",
    //   name: "sponsors",
    //   component: () =>
    //       import("@/views/sponsors.vue")
    // }
    {
      path: '*',
      redirect: { name: 'home' },
    },
  ],
  linkActiveClass: '',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
