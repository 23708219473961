export default {
  isFreeTrialOpen() {
    switch (new Date().getMonth()) {
      // August-October
      case 8:
      case 9:
      case 10:
        return true

      // November-July
      default:
        return false
    }
  },
  isRegistrationOpen() {
    switch (new Date().getMonth()) {
      // January, February & August-December
      case 0:
      case 1:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        return true

      default:
        return false
    }
  },
}
