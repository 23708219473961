<template>
  <div class="jumbotron jumbotron-fluid mb-0 pt-2 pb-0">
    <div class="container p-0">
      <div class="row mx-0 justify-content-center">
        <img
          class="d-block col-md-3 w-25 mb-1 p-0 rounded"
          style="max-width: 132px; max-height: 112px"
          src="/img/havHamLogo2019.jpg"
          alt=""
        />
        <div class="col-12 col-md-9 m-0 py-0 text-center text-md-left">
          <h3 class="m-0 banner">HAVERHILL HAMMERS YOUTH WRESTLING</h3>
          <p class="m-0 tag-line">
            <em
              >Bringing youth wrestling to Haverhill for more than 20 years!</em
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'banner',
}
</script>

<style scoped></style>
