<template>
  <div id="app">
    <!-- ****************************************************************** -->
    <!-- Use flex display and flex direction to display the nav and banner  -->
    <!-- components in the right order:                                     -->
    <!--           XS + SM Breakpoints: Nav above Banner                    -->
    <!--           MD, LG + XL Breakpoints: Banner above Nav                -->
    <!-- ****************************************************************** -->
    <div class="d-md-flex flex-md-column-reverse">
      <nav-bar>
        <!-- Component to deal with all of our routing and navigation -->
      </nav-bar>

      <banner class="mb-md-0">
        <!-- Component that makes our header nice and fancy looking -->
      </banner>
    </div>

    <router-view>
      <!-- Dynamic content populated by the route that was chosen -->
    </router-view>

    <h-footer>
      <!-- Component that makes our footer nice and fancy looking -->
    </h-footer>
  </div>
</template>

<script>
import navBar from '@/components/navBar'
import banner from '@/components/banner'
import hFooter from '@/components/hFooter'

export default {
  name: 'App',
  components: {
    navBar,
    banner,
    hFooter,
  },
  data() {
    return {}
  },
}
</script>

<style></style>
