import Vue from 'vue'
import App from './app.vue'
import router from './router'

// styles
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap'
import '@/assets/styles/theme.scss'

Vue.use(BootstrapVue)

import Toast from 'vue-toastification'
// import 'vue-toastification/dist/index.css'
Vue.use(Toast, {
  position: 'top-right',
  newestOnTop: true,
  maxToasts: 5,
  transition: 'Vue-Toastification__bounce',
  // in ms
  transitionDuration: 750,
  // dismiss by drag & swipe events
  draggable: true,
  draggablePercent: 0.6,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  closeOnClick: false,
  // in ms
  timeout: 6000,
  container: document.body,
  toastClassName: ['toast-error'],
  bodyClassName: [],
  // containerClassName:
  hideProgressBar: false,
  hideCloseButton: true,
  // custom icons here
  icon: true,
})

// icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faBars,
  faExternalLinkAlt,
  faUserPlus,
  faTrashAlt,
  faEnvelopeSquare,
  faFacebookSquare
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
Vue.component('vue-phone-number-input', VuePhoneNumberInput)

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
